// Dropdown menu
$("#primary-nav-action").click(function(e){e.preventDefault();$(".wrap").toggleClass("wrap--active");$(".primary-nav-action").toggleClass("primary-nav-action--active")});

// Dropdown menu arrow toggle
$(".has-children > span").first().css({ "z-index": "1" });

$(".has-children > span").click(function(e){
  e.preventDefault();
  $(this).parent().toggleClass("active");
});

// Picture element HTML5 shiv
document.createElement( "picture" );
